import React, { useEffect, useState } from 'react'
import "./DashBoard.css"
import { useNavigate } from "react-router-dom";
import "../page/AccessDenied"
import AccessDenied from '../page/AccessDenied';

const DashBoard = () => {
  const navigate = useNavigate();
  const [hasAccess,setHasAccess] = useState(false)
  useEffect(()=>{
    const user = localStorage.getItem("user")
    let i = JSON.parse(user)
    console.log(i.userType)
    if(i.userType == "university"){
      setHasAccess(true)
    }else{
      setHasAccess(false)
    }
  },[])
    const path = '/admin/dashboard/batchinfo'
    const batches = [
        {
            batchNo:2020,
            id:1
        },
        {
            batchNo:2021,
            id:1
        },
        {
            batchNo:2022,
            id:1
        },
    ]
    if(hasAccess){
  return (
    <div className='dashboard__wrapper'>
        <div className="cards__wrapper">
            {batches.map(i =>(
                <div key={i.id} className="card__container box" onClick={() => navigate(path,{state:{year:i.batchNo}})}>
                    <p>{i.batchNo}</p>
                </div>
            ))}
            </div>
    </div>
  )
    }else{
        return(
            // <h2>Access Denied</h2>
            <AccessDenied />
          )
    }
}

export default DashBoard
