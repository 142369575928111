import React, { useEffect, useState } from 'react'
import "./BatchInfo.css"
import StudenCard from '../StudenCard'
import { useLocation } from 'react-router-dom';
import "../page/AccessDenied"
import AccessDenied from '../page/AccessDenied';
const config = {
  headers: {
    "ngrok-skip-browser-warning": true
  }
}

const BatchInfo = () => {
    const {state} = useLocation();
    const {year} = state;
    console.log("Year: ", year)
    const [students,setStudents] = useState([])
    const [fetchData,setFetchData] = useState(false)

    const [hasAccess,setHasAccess] = useState(false)
  const URID = process.env.REACT_APP_NGROK_BACKEND_URI

  useEffect(()=>{
    const user = localStorage.getItem("user")
    let i = JSON.parse(user)
    console.log(i.userType)
    if(i.userType == "university"){
      setHasAccess(true)
    }else{
      setHasAccess(false)
    }
  },[])


    const fetchAllStudents = async ()=>{
    await fetch(`${URID}/api/students/all/${year}`, config)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      // Handle the response data here
      console.log("Debugging now")
      console.log(data?.students[0]);
      setStudents(data?.students)
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      console.error('Fetch error:', error);
    });
  }


  useEffect(()=>{
    fetchAllStudents()
  },[year])
  useEffect(()=>{
    fetchAllStudents()
  },[fetchData])
if(hasAccess){
  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {students &&
          students.map((i) => (
            <StudenCard
              cert_hash={i.cert_hash}
              name={i.name}
              regNo={i.regNo}
              yop={i.yearOfPassing}
              collegeName={i.collegeName}
              id={i._id}
              key={i._id}
              setFetchData={setFetchData}
            />
          ))}
      </div>
  )
    }else{
      return(
        // <h2>Access Denied</h2>
        <AccessDenied />
      )
    }
}

export default BatchInfo
