import React from 'react'
import "./StudentCard.css"
import { ethers, utils } from "ethers";
import { abi, contractAddress } from "../utils/constants";
const URID = process.env.REACT_APP_NGROK_BACKEND_URI
const REACT_APP_NGROK_BLOCKCHAIN_URI = process.env.REACT_APP_NGROK_BLOCKCHAIN_URI


const StudenCard = ({ cert_hash, name, regNo, yop, collegeName, id, setFetchData }) => {
  const config = {
    headers: {
      "ngrok-skip-browser-warning": true,
    },
  };
  const handleDelete = async () => {
    console.log(cert_hash)
     // delete from blockchain

     console.log("Deleting Transcript from blockchain");


     // let hash = ""
     // const providerUrl = 'http://localhost:8545'; // Replace with your local node's URL
     const providerUrl = REACT_APP_NGROK_BLOCKCHAIN_URI; // Replace with your local node's URL
    //  const privateKey = '0xac0974bec39a17e36ba4a6b4d238ff944bacb478cbed5efcae784d7bf4f2ff80';
    const privateKey = process.env.REACT_APP_PRIVATE_KEY;
     // try {
     //   await window.ethereum.request({ method: "eth_requestAccounts" });
     // } catch (error) {
     //   console.log(error);
     // }
     const provider = new ethers.providers.JsonRpcProvider({ url: providerUrl });
     const customWallet = new ethers.Wallet(privateKey, provider);
     // const provider = new ethers.providers.JsonRpcProvider("https://eth-sepolia.g.alchemy.com/v2/f6-isSCf5OjiqBkWaONyDJCeUe-OPlzG")
     // const signer = provider.getSigner();
     const contract = new ethers.Contract(contractAddress, abi, customWallet);
     console.log(contract)
     const transcript = await contract.transcripts(cert_hash);
     console.log(`transcript fetched : ${transcript}`);
     const transactionResponse = await contract.deleteTranscript(cert_hash);
     if(transactionResponse) console.log("Doc deleted from blockchain!")


    // Make a DELETE request to the server to delete the student
    fetch(`${URID}/api/students/delete/${id}`, {
      method: 'DELETE',
      headers: config.headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        alert("Document Deleted")
        setFetchData(true)
        // const data = response.json()
        // const hash = data[0]
        console.log("API Response delete student Route");
        console.log(response);
        return response.json();
      })
      .catch((error) => {
        console.error('Delete Student Error:', error);
      });

      // Delete the transaction
    fetch(`${URID}/api/students/delete_tx/${cert_hash}`, {
      method: 'DELETE',
      headers: config.headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        alert("Transaction Deleted")
        setFetchData(true)
        // const data = response.json()
        // const hash = data[0]
        console.log("API Response : delete_tx Route");
        console.log(response);
        return response.json();
      })
      .catch((error) => {
        console.error('Delete Student Error:', error);
      });
   


    
  };
  return (
    <div className="relative block overflow-hidden rounded-lg border border-gray-100 p-6 sm:p-6 lg:p-8 bg-white m-6">
      <span className="absolute inset-x-0 bottom-0 h-2 custom-gradient"></span>
      <span className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"></span>
        <div className="sm:flex sm:justify-between sm:gap-4">
          <div>
            <h3 className="text-lg font-bold text-gray-900 sm:text-xl text-center">
              Student Name: {name}
            </h3>
          </div>
        </div>

        <dl className="mt-6 flex gap-4 sm:gap-4 justify-center p-2">
          <div className="flex flex-col-reverse">
            <dt className="text-sm font-medium text-gray-600">Reg No: </dt>
            <dd className="text-xs text-gray-500">{regNo}</dd>
          </div>

          <div className="flex flex-col-reverse">
            <dt className="text-sm font-medium text-gray-600">
              Year Of Passing:{" "}
            </dt>
            <dd className="text-xs text-gray-500">{yop}</dd>
          </div>

          <div className="flex flex-col-reverse">
            <dt className="text-sm font-medium text-gray-600">
              College Name:{" "}
            </dt>
            <dd className="text-xs text-gray-500">{collegeName}</dd>
          </div>
          {/** Delete button commenting for client showcase
          <div className="flex flex-col-reverse">
            <button
              className="bg-red-500 text-white px-6 py-2 rounded"
              onClick={handleDelete}
            >
              Delete
            </button>
          </div>
          */}
        </dl>
      </div>
  )
}

export default StudenCard
