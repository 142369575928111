import { useState } from "react";
import { ethers, utils } from "ethers";
import ErrorMessage from "./ErrorMessage";
import axios from "axios";
import { abi, contractAddress } from "../utils/constants";
import "./SignMessage1.css";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import fs from 'fs'
const URID = process.env.REACT_APP_NGROK_BACKEND_URI
const REACT_APP_NGROK_BLOCKCHAIN_URI = process.env.REACT_APP_NGROK_BLOCKCHAIN_URI
const config = {
  headers: {
    "ngrok-skip-browser-warning": true
  }
}



const signFilesInFolder = async ({ setError, folder }) => {
  try {
    const files = folder.files;

    const signatures = [];

    for (const file of files) {
      console.log(file);

      const fileBuffer = await file.arrayBuffer();
      const hashArray = new Uint8Array(
        await crypto.subtle.digest("SHA-256", fileBuffer)
      );
      let hash = ethers.utils.hexlify(hashArray);
      console.log(`Exec py file and retrieve data.`)

      const formData = new FormData();
      formData.append('file', file);

        // python code start : harry mod

      // try {
      //   const response = await axios.post(`${URID}/ecdsa/py`, formData, {
      //     headers: {
      //       'Content-Type': 'multipart/form-data',
      //       "ngrok-skip-browser-warning": true
      //     },
      //   }).then(async (res) => {
      //     console.log(`ecdsa/py response`, res.data)
      //     let python_data = res.data.st_data
      //     const reversedString = python_data.replace(/['"]/g, function (match) {
      //       return match === '"' ? "'" : '"';
      //     });
      //     const python_data_json_obj = JSON.parse(reversedString)
      //     // const studentName = python_data_json_obj["Student Name"];
      //     // const studentID = python_data_json_obj["Student ID"];
      //     // const academicSession = python_data_json_obj["Academic Session"];
      //     // const university = python_data_json_obj["University"];
      //     python_data_json_obj["cert_hash"] = hash
      //     console.log(`St data in json format : ${JSON.stringify(python_data_json_obj)}`)
      //     // const newStudent = new Student({
      //     //   regNo,
      //     //   name,
      //     //   yearOfPassing,
      //     //   collegeName,
      //     //   cert_hash,
      //     // });
      //     const response = await axios.post(
      //       `${URID}/uploadStudent`,
      //       python_data_json_obj,
      //       config
      //     ).catch(error => {
      //       console.log(error.response.data.message)
      //       if (error.response.status === 409) {
      //         alert(`Student certificate ${error.response.data.message} already exists in the database!`)
      //       }
      //     })
      //     if (response.status === 201) {
      //       console.log("/uploadStudent res : ", response);
      //     }
      //   })

      // } catch (error) {
      //   console.log(`File upload failed : ${error}`)
      // }



      // py code end

      await axios
        .post(
          `${URID}/ecdsa/sign`,
          {
            text: hash,
          }, config
        )
        .then((res) => {
          console.log("/ecdsa/sign res : ", res.data);
          const signature = res.data.signature
          const address = res.data.signerAddress

          signatures.push({
            fileName: file.name,
            fileHash: hash,
            signature,
            signerAddress: address,
          });
        })
        .catch((err) => {
          console.log("API Error: ", err);
        });
    }
    // console.log(`signatures : ${signatures}`)
    return signatures;
  } catch (err) {
    setError(err.message);
  }
};

export default function SignMessage() {
  const [error, setError] = useState();
  const [signatures, setSignatures] = useState([]);
  const [csvContent, setCsvContent] = useState(null);

  const handleSign = async (e) => {
    e.preventDefault();
    setError();
    const folder = e.target.folder;
    if (!folder) {
      setError("Please select a folder.");
      return;
    }

    const files = Array.from(folder.files);
    setLoading(true)
    const sigs = await signFilesInFolder({
      setError,
      folder: { files },
    });
    console.log(`sigs : ${JSON.stringify(sigs)}`)
    setLoading(false)
    if (sigs && sigs.length > 0) {
      setSignatures([...signatures, ...sigs]);
    }
  };

  const generateCsvContent = () => {
    if (signatures.length === 0) {
      return;
    }

    const csvRows = [
      ["File Name", "File Hash", "Digital Signature", "Signer Address"],
    ];
    signatures.forEach((sig) => {
      csvRows.push([
        sig.fileName,
        sig.fileHash,
        sig.signature,
        sig.signerAddress,
      ]);
    });

    // console.log(csvRows)
    for (let i = 1; i < csvRows.length; i++) {
      console.log(csvRows[i]);
    }
    const csvContent = csvRows.map((row) => row.join(",")).join("\n");
    setCsvContent(csvContent);
  };

  const listenForTransactionMine = (transactionResponse, provider) => {
    console.log(`Mining Txn : ${transactionResponse.hash}`);
    return new Promise((resolve, reject) => {
      try {
        provider.once(transactionResponse.hash, (transactionReceipt) => {
          console.log(
            `Completed with ${transactionReceipt.confirmations} confirmations. `
          );
          resolve();
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const storeBlockchain = async () => {
    // fetching data to store on chain
    if (signatures.length === 0) {
      return;
    }
    console.log("Store blockchain fun exec")
    console.log(`signatures : ${signatures}`)
    setBlockLoading(true)

    const certs = [];
    signatures.forEach((sig) => {
      console.log(`Pushing to certs[] : ${sig.fileName}`)
      certs.push([
        sig.fileName,
        sig.fileHash,
        sig.signature,
        sig.signerAddress,
      ]);
    });
    console.log("certs", certs);
    // End of data fetch

    // Blockchain logic

    // if (typeof window.ethereum !== "undefined") {
    console.log("Storing Transcript on blockchain");
    const providerUrl = REACT_APP_NGROK_BLOCKCHAIN_URI; // Replace with your local node's URL
    // const privateKey = '0xac0974bec39a17e36ba4a6b4d238ff944bacb478cbed5efcae784d7bf4f2ff80';
    const privateKey = process.env.REACT_APP_PRIVATE_KEY;
    // try {
    //   await window.ethereum.request({ method: "eth_requestAccounts" });
    // } catch (error) {
    //   console.log(error);
    // }
    const provider = new ethers.providers.JsonRpcProvider({ url: providerUrl });
    const customWallet = new ethers.Wallet(privateKey, provider);
    // const provider = new ethers.providers.JsonRpcProvider("https://eth-sepolia.g.alchemy.com/v2/f6-isSCf5OjiqBkWaONyDJCeUe-OPlzG")
    // const signer = provider.getSigner();
    const contract = new ethers.Contract(contractAddress, abi, customWallet);
    // console.log(`fetched contract : ${contract}`);

    for (let i = 0; i < certs.length; i++) {
      const cert = certs[i];
      try {
        const transcript = await contract.transcripts(cert[1]);
        if (transcript[0] !== "") {
          console.log("Transcript already exists in blockchain");
          alert("Transcript already exists in blockchain");
          setBlockLoading(false);
        } else {
          const transactionResponse = await contract.addTranscript(cert);
          console.log(`Blockchain ID : ${transactionResponse.hash}`);
          
          // API call to store file hash and transaction response hash
          await axios.post(
            `${URID}/api/transactions/store`,
            {
              cert_hash: cert[1],
              trans_hash: transactionResponse.hash,
            }, config
          ).then((res) => {
            console.log("Tx store Response : ", res)
          })
          .catch((err) => {
            console.log("Tx Storing Error: ", err)
          })
          
          await listenForTransactionMine(transactionResponse, provider);
          const transaction = await provider.getTransaction(
            transactionResponse.hash
          );
          console.log("Transaction info", transaction);
          setBlockLoading(false);
        }
      } catch (error) {
        console.log(`Debug Error Reason : ${error.reason}`);
        console.log(error);
        setBlockLoading(false);
      }
    }
  };

  const handleDownload = () => {
    generateCsvContent();

    if (csvContent) {
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "file_signatures.csv";
      a.click();
    }
  };
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);
    setSelectedFiles(fileList);
  };

  const [showSelectedFiles, setShowSelectedFiles] = useState(false)
  const showFiles = () => {
    setShowSelectedFiles(!showSelectedFiles)
  }
  const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
  const [loading, setLoading] = useState(false);
  const [blockLoading, setBlockLoading] = useState(false);

  return (
    <div className="center-container">
      <form className="m-4" onSubmit={handleSign}>
        <div className="credit-card w-full shadow-lg mx-auto rounded-xl bg-white">
          <main className="mt-4 p-4">
            <h1 className="form-heading text-center">Securely Sign Documents</h1>
            <div className="">
              <div className="my-3">
                <div className="file-input-wrapper">
                  <label
                    htmlFor="file-input"
                    className={`file-input-label ${selectedFiles.length > 0 ? "active" : ""
                      }`}
                  >
                    {selectedFiles.length > 0
                      ? "Files Selected"
                      : "Choose File"}
                  </label>
                  <input
                    required
                    type="file"
                    id="file-input"
                    name="folder"
                    multiple
                    webkitdirectory="true"
                    className="file-input"
                    onChange={handleFileChange}
                  />
                </div>
                {selectedFiles.length > 0 && <p style={{cursor : "pointer"}} onClick={showFiles}>{showSelectedFiles ? "Hide Files" : "Show Selected Files"}</p>}
                {showSelectedFiles && selectedFiles.length > 0 && (
                  <div className="selected-files">
                    <p>Selected Files:</p>
                    <ul>
                      {selectedFiles.map((file, index) => (
                        <li key={index}>{file.name}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </main>
          <footer className="p-4">
          <button
              type="submit"
              className="btn btn-primary submit-button focus:ring focus:outline-none w-full"
            >
              {loading ? (
                <div className="spinner">
                  <ClipLoader css={override} size={20} color={"#fff"} />
                </div>
              ) : (
                "Sign"
              )}
            </button>
            <button
              type="button"
              onClick={handleDownload}
              className="btn btn-secondary submit-button focus:ring focus:outline-none w-full mt-2"
              disabled={signatures.length === 0}
            >
              Download CSV
            </button>
            <button
              type="button"
              onClick={storeBlockchain}
              className="btn btn-secondary submit-button focus:ring focus:outline-none w-full mt-2"
              disabled={signatures.length === 0}
            >
             {blockLoading ? (
                <div className="spinner">
                  <ClipLoader css={override} size={20} color={"#fff"} />
                </div>
              ) : (
                "Store on Blockchain"
              )}
            </button>
            <ErrorMessage message={error} />
          </footer>
        </div>
      </form>
      {/* <h1> Nothing</h1> */}
    </div>
  );
}
